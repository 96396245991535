/* You can add global styles to this file, and also import other style files */
/* @import "~ngx-spinner/animations/ball-circus.css"; */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}
.swal2-popup .swal2-title {
  padding: 50px !important;
}
.swal2-toast .swal2-title {
  padding: 0 !important;
}